<template>
  <div>
    <CampusHeader
        :activeTab="0"
        :can-create="false"
        :can-select="false"
        :header-text="'My Activities'"
        :header_tabs="[{ title: `Activities List` }]"
        :manage-campuses="false"
        :show-campus="false"
    ></CampusHeader>
    <section class="section pt-4 box">

      <div class="">
        <div class="columns">
          <div class="column is-narrow">
            <b-field grouped>
              <b-field>
                <b-radio-button v-model="query.is_complete" :native-value="false">
                  <b-icon :icon="$tc('icons.minus')"></b-icon>
                  <span>Incomplete</span>
                </b-radio-button>

                <b-radio-button v-model="query.is_complete" :native-value="true">
                  <b-icon :icon="$tc('icons.tick')"></b-icon>
                  <span>Complete</span>
                </b-radio-button>
              </b-field>
              <b-field>
                <b-radio-button v-model="query.model_type" native-value="user">
                  <b-icon :icon="$tc('icons.users')"></b-icon>
                  <span>{{ $tc('Learner', 2) }}</span>
                </b-radio-button>

                <b-radio-button v-model="query.model_type" native-value="applications">
                  <b-icon :icon="$tc('icons.applications')"></b-icon>
                  <span>Applications</span>
                </b-radio-button>
              </b-field>
              <b-field>
                <b-taglist class="margin-left">
                  <b-tag
                      v-if="query.is_complete!=null"
                      closable
                      type="is-info"
                      @close="query.is_complete = null"
                  >Status
                  </b-tag>
                  <b-tag
                      v-if="query.model_type!=null"
                      closable
                      type="is-info"
                      @close="query.model_type = null"
                  >Type
                  </b-tag>
                </b-taglist>
              </b-field>
            </b-field>
          </div>
          <!-- <div class="column is-half">
            <b-field label="Filter" horizontal>
              <b-input
                v-debounce:300ms="getFilteredCourse"
                placeholder="Type any parameter here"
                :loading="loadingFilter"
                v-model="filter"
              ></b-input>
            </b-field>
          </div>-->
        </div>
        <b-table
            ref="table"
            :bordered="false"
            :data="activities"
            :hoverable="true"
            :opened-detailed="defaultOpenedDetails"
            :show-detail-icon="false"
            :striped="true"
            class="margin-top"
            detail-key="id"
            detailed
            narrowed
        >
          <template #empty>
            <div class="has-text-centered">No Activities</div>
          </template>
          <b-table-column v-slot="props" centered field="is_complete" label="Status" sortable width="20">
            <b-tooltip
                :label="props.row.is_complete!==1?'Mark as complete':'Mark as incomplete'"
                position="is-top"
            >
              <b-button
                  :loading="loadingActivities.includes(props.row.id)"
                  :type="{'is-success':props.row.is_complete===1}"
                  class="rounded"
                  size="is-small"
                  @click.prevent="completeActivity(props.row)"
              >
                <b-icon :icon="$tc('icons.tick')" size="is-small"></b-icon>
              </b-button>
            </b-tooltip>
          </b-table-column>
          <b-table-column v-slot="props" centered field="type_id" label="Task" sortable width="20">
            <b-tooltip v-if="loaded" :label="type(props.row.type_id).name" position="is-top">
              <b-icon :icon="$tc(`icons.${type(props.row.type_id).name}`)" class="icon" size="is-small"></b-icon>
            </b-tooltip>
          </b-table-column>

          <b-table-column v-slot="props" field="name" label="Name" sortable>
            <div v-if="props.row.name.length<20" v-html="clipDescription(props.row.name)"></div>
            <a
                v-else
                class="has-text-info"
                @click="toggle(props.row)"
                v-html="clipDescription(props.row.name)"
            ></a>
          </b-table-column>
          <b-table-column v-slot="props" field="note" label="Description">
            <div v-if="props.row.note==null"></div>
            <div v-else-if="props.row.note.length<20" v-html="clipDescription(props.row.note)"></div>
            <a
                v-else
                class="has-text-info"
                @click="toggle(props.row)"
                v-html="clipDescription(props.row.note)"
            ></a>
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="creator.first_name"
              label="Creator"
              sortable
          >{{ props.row.creator.first_name }} {{ props.row.creator.last_name }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="created_at"
              label="Created at"
              sortable
          >{{ (new Date(props.row.created_at).toLocaleString()) }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="due_at"
              label="Due at"
              sortable
          >{{ props.row.due_at != null ? (new Date(props.row.due_at).toLocaleString()) : '' }}
          </b-table-column>

          <b-table-column v-slot="props" custom-key="actions" field="taskable_type" label="View" sortable>
            <b-button
                class="is-capitalized"
                type="is-primary"
                @click="goToEntity(props.row)"
            >View {{ extractTaskableType(props.row.taskable_type).slice(0, -1) }}
            </b-button>
          </b-table-column>
          <template slot="detail" slot-scope="props">
            <b-field label="Name">
              <div v-html="props.row.name"></div>
            </b-field>
            <b-field v-if="props.row.note !=null" label="Description">
              <div class="ck-content" v-html="props.row.note"></div>
            </b-field>
          </template>
        </b-table>
        <b-pagination
            :current="page"
            :per-page="page_limit"
            :range-after="2"
            :range-before="2"
            :total="meta.total"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            class="mt-4"
            v-on:change="setPage"
        ></b-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import Activity from "@/models/Task";
import clip from "text-clipper";
import ActivityType from "@/models/TaskType";
import CampusHeader from "@/components/panelled-dash/CampusHeader";

export default {
  name: "Activities.Index",
  components: {CampusHeader},
  data() {
    return {
      query: {is_complete: null, model_type: null},
      defaultOpenedDetails: [],
      loadingActivities: [],
      loadingFilter: false,
      loaded: false,
      courses: [],
      meta: Object,
      page: 1,
      page_limit: 15,
    };
  },
  watch: {
    filter() {
      this.getActivities(this.filter, 1, 999);
    },
  },
  methods: {
    extractTaskableType(taskable_type) {
      if (taskable_type.includes('Application')) {
        return 'applications'
      }
      if (taskable_type.includes('User')) {
        return 'users'
      }
      if (taskable_type.includes('Enrolment')) {
        return 'enrolments'
      }
    },
    extractNavigationType(taskable_type) {
      if (taskable_type.includes('Application')) {
        return 'applications'
      }
      if (taskable_type.includes('User')) {
        return 'learners'
      }
      if (taskable_type.includes('Enrolment')) {
        return 'enrolments'
      }
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    goToEntity(activity) {
      if (activity.taskable_type === 'learners') {
        this.$router.push(`/${this.extractNavigationType(activity.taskable_type)}/edit/${activity.taskable_id}?tab=activities`)
        return
      }
      this.$router.push(`/${this.extractNavigationType(activity.taskable_type)}/edit/${activity.taskable_id}`)

    },
    type(type_id) {
      return ActivityType.query().whereId(type_id).first();
    },
    clipDescription(text) {
      return clip(text, 20, {
        html: true,
      });
    },
    completeActivity(activity) {
      this.loadingActivities.push(activity.id);
      Activity.Toggle(activity.id, {
        type: this.extractTaskableType(activity.taskable_type),
        id: activity.taskable_id
      }).then(() => {
        this.loadingActivities = this.loadingActivities.filter(
            (current_id) => current_id !== activity.id
        );
      });
    },
    setPage(pageNumber) {
      this.page = pageNumber;
    },
    getActivities(query, page = 1, limit = 15) {
      this.$store.dispatch("loader/show");
      Activity.deleteAll();
      return Activity.FetchAll({page: page, limit: limit}, query, ['creator']).then((result) => {
        this.meta = result.response.data.meta;
        this.$store.dispatch("loader/hide");
      }).catch(
          err => {
            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      );
    },
  },

  computed: {
    activity_types() {
      return ActivityType.query().get()
    },
    filter() {
      return {...{assigned_to_user_id: this.userId}, ...this.query};
    },
    userId() {
      return parseInt(this.$store.state.user.id);
    },
    activities() {
      return Activity.query()
          .where("assigned_to_user_id", this.userId)
          .withAll()
          .orderBy("created_at", "desc")
          .offset((this.page - 1) * this.page_limit)
          .limit(this.page_limit)
          .get();
    },

  },
  mounted() {

    let get = this.getActivities(this.filter, 1, 999);
    let types = ActivityType.exists() ? true : ActivityType.FetchAll({page: 1, limit: 999})

    Promise.all([get, types]).then(() => {
      this.loaded = true
    })

  },
};
</script>
